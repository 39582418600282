/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import CarouselImage from "../assets/work.gif";

const Hero = () => {
  return (
    <>
      <div className="-mt-4">
        <div className="hero-alternative">
          <div className="hero-alternative-intro">
            <img
              src="https://i.ibb.co/6DcC5Jj/276994740-364035949063863-3355795617466728307-n.jpg"
              alt="Logo for Sweets By Karla"
              className="mb-3 rounded-full w-25"
            />

            <p className="mb-3">
              Get ready to Experience a delectable journey where heavenly
              flavors and irresistible aromas dance together, indulging your
              senses in a symphony of sweetness.
            </p>
            <div>
              <Link
                to="/products"
                className="btn bg-azalea-500 text-white w-1/4 hover:bg-azalea-400 rounded shop-button"
              >
                <i className="fas fa-shopping-bag"></i> Shop Now
              </Link>
              <Link
                to="/support"
                className="w-1/5 ml-2 rounded btn contact-button"
              >
                <i className="fas fa-envelope"></i> Contact Us
              </Link>
            </div>
          </div>
          {/* GIF */}
          <div className="products-gif">
            <img src={CarouselImage} alt="hero" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
