/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

const OurStory = () => {
  return (
    <>
      <div className="flex flex-col story section-styling lg:flex-row">
        <img
          src="https://i.ibb.co/gjBCL0g/362626718-746860687448052-4404361701354070540-n-2.jpg"
          alt="store"
          className="hidden rounded-md lg:w-1/6 lg:block "
        />
        <img
          src="https://tb-static.uber.com/prod/image-proc/processed_images/e50438200ab8a7d8d99eb24342821e86/3ac2b39ad528f8c8c5dc77c59abb683d.jpeg"
          alt="store2"
          className="w-full mb-3 rounded-md lg:hidden"
        />

        <div className="flex flex-col ml-3 ">
          <h1 className="font-bold title">Just a bit about us...</h1>
          <p className="mt-2 mb-2 text-xl font-medium">
            Our bakery is a small, family-owned business that has been serving
            the Kissimmee community for over 10+ years. We are passionate about
            baking delicious, fresh-made pastries and desserts that our
            customers love.
          </p>
          <p className="mt-2 mb-2 text-xl font-medium">
            We use only the finest ingredients and traditional baking methods to
            create our pastries. We believe that baking is an art form, and we
            take great pride in our work.
          </p>
          <p className="mt-2 mb-2 text-xl font-medium">
            We are committed to providing our customers with the best possible
            experience. We offer friendly and attentive service, and we are
            always willing to go the extra mile to make sure our customers are
            satisfied. We are located in the heart of Kissimmee, and we are open
            seven days a week. We hope to see you soon!
          </p>
          <img
            src="https://images.getbento.com/accounts/a0e834cb8df765b9862ad99cdc1971cf/media/images/Little-Tart_rolling-pin.gif?w=1000&fit=max&auto=compress,format&h=1000"
            alt="rolling-tart-gif"
            className="w-1/12"
          />
        </div>
      </div>
    </>
  );
};

export default OurStory;
