import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Product from "../components/Product";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import Meta from "../components/Meta";
import { listProducts } from "../actions/productActions";
// import LatestProducts from "../components/LatestProducts";
import ShopByCat from "../components/ShopByCategory";
// import ShopByBrand from "../components/ShopByBrand";
import Sort from "../components/Sort";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import CakeGrid from "../components/product/CakeGrid";

const Products = ({ match, history, location }) => {

  const [isOpen, setIsOpen] = useState(false);

  const keyword = match.params.keyword;

  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  const SpringModal = ({ isOpen, setIsOpen }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
            className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
          >
            <motion.div
              initial={{ scale: 0, rotate: "12.5deg" }}
              animate={{ scale: 1, rotate: "0deg" }}
              exit={{ scale: 0, rotate: "0deg" }}
              onClick={(e) => e.stopPropagation()}
              className="bg-gradient-to-br bg-pink-500/80 text-white p-6 rounded-lg w-full  shadow-xl cursor-default relative overflow-hidden"
            >

              <div className="relative z-10">

                <h3 className="text-3xl font-bold text-center mb-2">
                  Categories for Cakes
                </h3>

                <CakeGrid />

                <div className="flex gap-2">
                  <Button
                    onClick={() => setIsOpen(false)}
                    className="bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded"
                  >
                    Close
                  </Button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <>
      <Meta />
      <span id="prod"></span>
      <main className="py-3">
        <Container>
          {/* <ShopByCategory products={products} /> */}
          <h1 className="text-[2em] font-bold font-sans mt-4 mb-4">
            Product Ready To Be{" "}
            <span className="font-extrabold text-slate-600">Customized</span>{" "}
            ...
          </h1>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <>
              {/* Show this only if page 1 or in /products page */}

              <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} />

              {page === 1 && !keyword && (
                <div className="mt-4 mb-4 ">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 cat-cards-cont">
                    <div className="p-8 text-black bg-[#F4F0DD] rounded-lg shadow-lg cat-cards-1">
                      <h1 className="mb-1 font-medium text-black">Cakes</h1>
                      <p className="text-base">
                        Make your life sweeter with our cakes
                      </p>

                      <Button onClick={() => setIsOpen(true)} className="rounded-md bg-slate-600 btn hover:bg-slate-700">
                        View Sub-Categories
                      </Button>

                    </div>
                    <div className="p-8 text-black bg-[#F4F0DD] rounded-lg shadow-lg cat-cards-2">
                      <h1 className="mb-1 font-medium">Accessories</h1>
                      <p className="text-base">
                        Tools to make your baking experience better
                      </p>
                      <Link to="/category/Accessories">
                        <Button className="rounded-md bg-slate-600 btn hover:bg-slate-700">
                          Browse This Category
                        </Button>
                      </Link>
                    </div>
                    <div className="p-8 text-black bg-[#F4F0DD] rounded-lg shadow-lg cat-cards-3">
                      <h1 className="mb-1 font-medium">Baking</h1>
                      <p className="text-base">
                        Baked Goods Like Cupcakes, etc.
                      </p>
                      <Link to="/category/Baking">
                        <Button className="rounded-md bg-slate-600 btn hover:bg-slate-700">
                          Browse This Category
                        </Button>
                      </Link>
                    </div>
                    <div className="p-8 text-black bg-[#F4F0DD] rounded-lg shadow-lg cat-cards-4">
                      <h1 className="mb-1 font-medium">Other</h1>
                      <p className="text-base">
                        Anything else your mind can imagine
                      </p>
                      <Link to="/category/Other">
                        <Button className="rounded-md bg-slate-600 btn hover:bg-slate-700">
                          Browse This Category
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              <ShopByCat />
              <Row>
                <Col md={3} className="mb-2">
                  <h6>Sort By:</h6>
                  <Sort products={products} pages={pages} page={page} />
                </Col>
              </Row>
              {products.map((product) => (
                <Row key={product._id} className="items-center justify-center">
                  <Product product={product} className="self-stretch mb-3" />
                </Row>
              ))}
              <Paginate
                pages={pages}
                page={page}
                keyword={keyword ? keyword : ""}
              />
              {/* <ShopByBrand products={products} /> */}
            </>
          )}
        </Container>
      </main>
    </>
  );
};

export default Products;
