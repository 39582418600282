//  useState, useRef } from "react";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import Message from "../components/Message";
import { addToCart } from "../actions/cartActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import { saveOrderNotes } from "../actions/cartActions";

// import ShippingRates from "../components/ShippingRates";

// import PayButton from "../components/pay";

const AdditionalDetails = ({ match, location, history }) => {
  const productId = match.params.id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  // console.log(location.search)
  // console.log("add qty", qty)

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems, orderNotes } = cart;
  // console.log(cartItems)
  console.log("Order Notes: ", orderNotes);

  const [notes, setOrderNotes] = useState(orderNotes);
  console.log("Notes", notes);

  // laoindg sate
  // const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [showButton, setShowButton] = useState(false);

  const editor = useRef(null);
  const config = {
    readonly: false,
    placeholder: "Write Order Notes",
    askBeforePasteHTML: false,
  };

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
    dispatch(saveOrderNotes(orderNotes));
  }, [dispatch, productId, qty, orderNotes]);

  const submitHandler = async (e) => {
    e.preventDefault();
    // setLoading(true);
    dispatch(saveOrderNotes(notes));
    toast.success("Order Notes Saved");
    // redirect to "/checkout" page
    history.push("/checkout");
  };

  return (
    <>
      <ToastContainer />
      <Row>
        <Col md={7}>
          <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4">
            <Link to="/cart" className="hover:no-underline">
              <li className="flex items-center text-blue-400 ">
                <span className="flex items-center justify-center w-5 h-5 mr-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                  1
                </span>
                Cart
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 ml-2 sm:ml-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  ></path>
                </svg>
              </li>
            </Link>
            <li className="flex items-center">
              <span className="flex items-center justify-center w-5 h-5 mr-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                2
              </span>
              Review & Pay
            </li>
          </ol>

          {cartItems.length === 0 ? (
            <Message>
              Your cart is empty <Link to="/">Go Back</Link>
            </Message>
          ) : (
            <>
              <Card className="mt-2">
                <ListGroup variant="flush">
                  <ListGroup.Item className="border-transparent">
                    <h2 className="text-[2em] uppercase font-bold font-sans text-black">
                      Shipping / Order Notes
                    </h2>
                  </ListGroup.Item>

                  <ListGroup.Item className="border-transparent">
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="orderNotes">
                        <Form.Label className="mt-3 mb-2 font-medium">
                          Requests? Notes For The Order?
                        </Form.Label>
                        <JoditEditor
                          id="description"
                          ref={editor}
                          value={notes}
                          config={config}
                          tabIndex={1}
                          onChange={(e) => {}}
                          onBlur={(e) => setOrderNotes(e)}
                        />
                      </Form.Group>

                      <div className="flex">
                        <Link to="/checkout">
                          <Button className="bg-red-500 btn hover:bg-red-600">
                            No Notes. Proceed to Checkout.
                          </Button>
                        </Link>

                        <Button
                          type="submit"
                          variant="primary"
                          className="bg-green-600 hover:bg-green-700"
                        >
                          Submit your order notes and proceed to checkout.
                        </Button>
                      </div>

                      {/* <Button
                        type="submit"
                        variant="primary"
                        className="w-full bg-slate-600 hover:bg-slate-700"
                      >
                        {loading ? (
                          <>
                            <div
                              role="status"
                              className="flex items-center justify-center"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-white"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="text-white">Loading...</span>
                            </div>
                          </>
                        ) : (
                          "Save Notes, & Continue"
                        )} </Button> */}
                    </Form>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </>
          )}
        </Col>
        <Col md={5} className="mt-2 rounded-md">
          <h2 className="text-2xl font-bold">
            Cart (Qty: {cartItems.reduce((acc, item) => acc + item.qty, 0)}
            ):
          </h2>
          {cartItems.map((item, index) => (
            <div key={index}>
              <ListGroup.Item key={item.product} className="border-transparent">
                <Row className="flex items-center justify-between p-2 py-4 bg-gray-100 rounded">
                  <Col md={2}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link
                      to={`/product/${item.product}`}
                      className="font-medium"
                    >
                      {item.name}
                      {item.option && <p>({item.option})</p>}
                    </Link>
                  </Col>
                  <Col md={2} className="font-medium">
                    ${item.newPrice}
                  </Col>
                  <Col md={2} className="font-medium">
                    QTY: {item.qty}
                  </Col>
                </Row>
              </ListGroup.Item>
            </div>
          ))}
          <Row className="mb-3 ">
            <hr
              style={{ border: "1px solid black", width: "100%" }}
              className="mt-3"
            />
            <p className="mt-2 text-[1.3em] font-medium">
              Cart Value: $
              {cartItems
                .reduce((acc, item) => acc + item.qty * item.newPrice, 0)
                .toFixed(2)}
            </p>
          </Row>
          {/* {state.length > 0 && ( */}
        </Col>
      </Row>
    </>
  );
};

export default AdditionalDetails;
