import React from 'react';
import { Link } from 'react-router-dom';

const categories = [
    {
        name: 'Wedding Cakes',
        image: 'https://i.ibb.co/VgW963N/E66-E5860-3-BD0-448-D-B22-A-85208-B51-B1-D2.jpg',
        link: "/category/Wedding-Cakes"
    },
    {
        name: 'Quinceanera',
        image: 'https://i.ibb.co/ZKny2S4/IMG-4187.jpg',
        link: "/category/Quinceanera"
    },
    {
        name: 'Birthday Cakes',
        image: 'https://i.ibb.co/6JZQq54/IMG-5551.jpg',
        link: "/category/Birthday-Cakes"
    },
    {
        name: 'Baby Shower',
        image: 'https://i.ibb.co/X5tbJjW/Screenshot-2023-08-03-at-10-01-33-AM.png',
        link: "/category/Baby-Shower"
    },
];

const CakeGrid = () => {
    return (
        <div className="flex flex-wrap gap-4 m-3 items-center justify-center">
            {categories.map((category, index) => (
                <Link to={category.link}>
                    <div key={index} className="flex flex-col items-center no-underline hover:no-underline">
                        <div
                            className="w-32 h-32 md:w-80 md:h-80 bg-cover bg-center mb-2 flex justify-center items-center rounded-lg no-underline hover:no-underline"
                            style={{ backgroundImage: ` url(${category.image})` }}
                        >
                            <div className="bg-black bg-opacity-40 hover:bg-opacity-60 hover:transition-all hover:ease-in-out duration-300 text-white font-bold text-xl text-center p-2 rounded w-32 h-32 md:w-80 md:h-80 flex justify-center items-center no-underline hover:no-underline">{category.name}</div>
                        </div>

                    </div>
                </Link>
            ))}
        </div>
    );
};

export default CakeGrid;
