import React from "react";

const WhatWeOffer = () => {
  return (
    <>
      <div className="section-styling">
        <h1 className="font-bold section-title">What We Offer</h1>
        <div className="flex flex-col items-center justify-center w-full offer-cards-container lg:flex-row">
          <div className="card1">
            <h2 className="text-2xl font-bold">Cakes</h2>
            <p className="mt-2 mb-2 text-xl font-medium">
              We offer a wide variety of cakes for any occasion. Our cakes are
              made with the finest ingredients and are always fresh. All made to
              order.
            </p>
          </div>
          <div className="card2">
            <h2 className="text-2xl font-bold">Pastries</h2>
            <p className="mt-2 mb-2 text-xl font-medium">
              We offer a wide variety of pastries for any occasion. From our
              famous quesitos to our pastelitos de guayaba, we have something
              for everyone.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeOffer;
