import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_ORDERNOTES,
  CART_CLEAR_ITEMS,
} from "../constants/cartConstants";

export const cartReducer = (
  state = {
    cartItems: [],
    orderNotes: "",
    cdnURL: "",
  },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      //console.log("State Cart Items: ", state.cartItems)
      //console.log("Item: ",item.product)

      const existItem = state.cartItems.find((x) => x.product === item.product && x.option === item.option);

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }
    case CART_REMOVE_ITEM:
      console.log("Delete payload: ", action.payload)
      const {product, option} = action.payload
      console.log("Cart Items: ", state.cartItems)
      return {
        ...state,
        cartItems: state.cartItems.filter((x) =>x.product !== product || (x.product === product && x.option !== option)),
      };

    case CART_SAVE_ORDERNOTES:
      return {
        ...state,
        orderNotes: action.payload,
      };

    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};
